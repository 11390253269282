<script>
import EmailAccount from "../../../components/email_account";

export default {
    components:{
        EmailAccount
    },
}
</script>

<template>
  <EmailAccount />
</template>
